<template lang="pug">   
div  
  section
    nav.navbar.has-background-link-light(role="navigation", aria-label="main navigation")
      .navbar-brand
        a.navbar-item(href="")
          img.logo(src="/images/ReLowCode_Logo.png")
        a.navbar-burger(role="button", aria-label="menu", aria-expanded="false", @click="isOpen = !isOpen", v-bind:class="{'is-active': isOpen}")
          span(aria-hidden="true")
          span(aria-hidden="true")
          span(aria-hidden="true")
      .navbar-menu(v-bind:class="{'is-active': isOpen}")
        .navbar-end
          a.navbar-item Home
          a.navbar-item My Account
          a.navbar-item(@click="signOut") Sign Out 
  section
    slot
    
</template>
<script setup>
const {signOut } = useAuth()
const isOpen = ref(false)



</script>